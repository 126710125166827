$grid-breakpoints: (
  xs: 375px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

$grid-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

$grid-padding-width: 10px !default;

$grid-padding-widths: (
  xs: $grid-padding-width,
  sm: $grid-padding-width,
  md: $grid-padding-width,
  lg: $grid-padding-width,
  xl: $grid-padding-width
) !default;


/** Devices breakpoints **/

$iphone-x-height: 768px;
$ios-tablet-landscape-height: 813px;
$laptop-client-height: 768px;
