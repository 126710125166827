.u-p1,
.u-p2,
.u-p3,
.u-p4,
.u-p5,
.u-p6 {
  margin: 0;
  padding: 0;

  // Overwrite Ionic Styles
  &.u-important {
    margin: 0 !important;
    padding: 0 !important;

    &.u-medium-grey {
      color: var(--medium-grey) !important;
    }
  }
}


.u-p1 {
  color: var(--medium-grey);
  font-size: 18px;
  font-weight: var(--gotham-book);
  line-height: 20px;
  letter-spacing: 0;
}


.u-p2 {
  color: var(--dark-grey);
  font-size: 16px;
  font-weight: var(--gotham-book);
  line-height: 22px;
  letter-spacing: 0;
}


.u-p3 {
  color: var(--anthracite);
  font-size: 14px;
  font-weight: var(--gotham-book);
  line-height: 16px;
  letter-spacing: 0;

  // Overwrite Ionic Styles
  &.u-important {
    color: var(--anthracite) !important;
    font-size: 14px !important;
    font-weight: var(--gotham-book) !important;
    line-height: 16px !important;
    letter-spacing: 0 !important;
  }
}

.u-p4 {
  color: var(--anthracite);
  font-size: 14px;
  font-weight: var(--gotham-light);
  line-height: 18px;
  letter-spacing: 0;
}

.u-p5 {
  color: var(--medium-grey);
  font-size: 14px;
  font-weight: var(--gotham-medium);
  line-height: 1.71;
  letter-spacing: normal;
}

.u-p6 {
  color: var(--medium-grey);
  font-size: 12px;
  font-weight: var(--gotham-light);
  line-height: 14px;
  letter-spacing: 0;
}
